import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { fadeIn, fadeOut } from '@app/animations/animations';
import { isNullish } from '@commonHelpers/math-utils';
import { TranslateModule } from '@commonNodeModules/@ngx-translate/core';
import { SafeUrlPipe } from '@commonPipes/safeUrl';
import { ViewType } from '@enums/view-type';
import { DisplaySetting, ViewGroup, ViewGroupItemDto, Viewer } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { ALERT_POSITION_TYPES } from '@services/alert.service';
import { ClipboardService } from '@services/clipboard.service';
import { ContextMenuService } from '@services/context-menu.service';
import { MarkedTextService } from '@services/marked-text.service';
import { MetadataDownloadService } from '@services/metadata-download.service';
import { ViewTypeService } from '@services/view-type.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription, timer } from 'rxjs';
import { ContextMenuComponent } from '../../components/context-menu/context-menu.component';
import { ContextMenuOpenDirective } from '../../directives/context-menu-open.directive';
import { AdditionalinfoviewerComponent } from '../additionalinfoviewer/additionalinfoviewer.component';
import { HtmlviewerComponent } from "../htmlviewer/htmlviewer.component";
import { TitleViewerComponent } from '../title-viewer/title-viewer.component';

@Component({
	selector: 'app-overview-viewer',
	templateUrl: './overview-viewer.component.html',
	styleUrls: ['./overview-viewer.component.scss'],
	animations: [
		fadeIn,
		fadeOut
	],
	standalone: true,
	providers: [ContextMenuService, MetadataDownloadService],
	imports: [ContextMenuComponent, ContextMenuOpenDirective, NgClass, TitleViewerComponent, NgTemplateOutlet, AdditionalinfoviewerComponent, RouterLink, SafeUrlPipe, TranslateModule, HtmlviewerComponent]
})
export class OverviewViewerComponent implements OnInit, OnDestroy, OnChanges {
	ALERT_POSITION_TYPES = ALERT_POSITION_TYPES

	@Input() mainViewer: Viewer;
	@Input() attachment: ViewGroupItemDto;
	@Input() attachments: ViewGroupItemDto[];
	@Input() isSearchMode = false;
	@Input() catalogViewShowPrice: DisplaySetting;
	public isCopyModeSelectDialogShown = false;
	public copyWholeText = false;
	private isCkeyPressed: boolean;
	private isInsertKeyPressed: boolean;
	private isControlKeyPressed: boolean;
	private viewTypeSubscription: Subscription;
	public currentViewType: ViewType;
	public viewType = ViewType;

	public markedTextSubscription: Subscription
	public copyFullTextSubscription: Subscription;
	public hasMarkedText: boolean;
	public copyToClipboardIsUsable = false;
	public imageAttachment: ViewGroupItemDto[];
	public loadContextMenu = false;

	constructor(
		private markedTextService: MarkedTextService,
		private route: ActivatedRoute,
		private viewTypeService: ViewTypeService,
		private deviceDetectorService: DeviceDetectorService,
		private elRef: ElementRef,
		private clipboardService: ClipboardService,
		private contextMenuService: ContextMenuService
	) { }

	async ngOnInit() {
		this.markedTextSubscription = this.markedTextService.getState().subscribe(hasMarkedText => { this.hasMarkedText = hasMarkedText })
		this.viewTypeSubscription = this.viewTypeService.currentViewType$.subscribe(async currentViewType => {
			this.currentViewType = currentViewType
		});
		this.copyToClipboardIsUsable = await this.clipboardService.isCopyToClipboardIsUsable()

		this.contextMenuService.getMenuState().subscribe(state => this.loadContextMenu = true);



	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.attachment?.currentValue) {
			const scrollContainer = this.elRef.nativeElement.querySelector('#scrollcontainer');
			if (scrollContainer) {
				scrollContainer.scrollTop = 0;
			}
		}

		this.imageAttachment = this.attachments?.filter(attachment => attachment.viewer === Viewer.ImageViewer).slice(0, 3) ?? [];
	}

	ngOnDestroy() {
		this.markedTextSubscription?.unsubscribe()
		this.viewTypeSubscription?.unsubscribe()
		if (this.deviceDetectorService.browser === "Safari") {
			this.copyFullTextSubscription?.unsubscribe();
		}

	}

	async onKeyUp(event: KeyboardEvent) {
		if (this.checkCtrlCPressed(event)) {
			this.contextMenuService.copySelectedClick();
			this.loadContextMenu = true;
		}
	}

	private checkCtrlCPressed(event: KeyboardEvent) {
		if (event.key === 'c' && event.ctrlKey) {
			return true;
		}
		// Sonderfall wenn es über die Events nicht registriert wurde
		switch (event.key) {
			case "c":
				this.isCkeyPressed = true
				const keyCTimer = timer(300).subscribe(() => {
					this.isCkeyPressed = false
					keyCTimer.unsubscribe()
				})
				break;
			case "Control":
				this.isControlKeyPressed = true
				const keyControllTimer = timer(300).subscribe(() => {
					this.isControlKeyPressed = false
					keyControllTimer.unsubscribe()
				})
				break;
			case "Insert":
				this.isInsertKeyPressed = true
				const keyInsertTimer = timer(300).subscribe(() => {
					this.isInsertKeyPressed = false
					keyInsertTimer.unsubscribe()
				})
				break;
		}
		if ((this.isCkeyPressed && this.isControlKeyPressed) ||
			(this.isInsertKeyPressed && this.isControlKeyPressed)
		) {
			this.isCkeyPressed = false;
			this.isControlKeyPressed = false;

			return true
		}
		return false
	}

	public getImgTab(tab: ViewGroup) {
		return ViewGroup[tab];
	}

	public getImageUrl(id: number) {
		const view = !isNullish(this.route.snapshot.params) ? `/${this.route.snapshot.params.view}` : null;
		const attachmentPart = `/attachment/${id}`
		if (!isNullish(view) && window.location.pathname.includes(view)) {
			return window.location.pathname.replace(view, `${attachmentPart}${view}`)
		}
		return `${window.location.pathname}${attachmentPart}`;
	}



	async validateCopySuccess(copiedText: string) {
		const clipboardContent = await navigator.clipboard.readText()
		return this.removeLineBreaksAndSpaces(clipboardContent) === this.removeLineBreaksAndSpaces(copiedText)
	}

	removeLineBreaksAndSpaces(string: string) {
		return string.replace(/(\r\n|\n|\r)/gm, "").replace(/\s/g, '')
	}


}
