import { Injectable } from '@angular/core';
import { isInIframe } from '@app/helpers/iframe-helper';
import { isFullscreen } from '@commonHelpers/fullscreen-helper';
import { ViewType } from '@enums/view-type';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ALERT_POSITION_TYPES } from './alert.service';
import { CssVariableService } from './css-variable.service';

@Injectable({ providedIn: 'root' })
export class ViewTypeService {

	private currentViewType: ViewType;
	private isEmbeded = false;


	// BehaviorSubject, das den ViewType aussendet gemäß der aktuellen Bildschirmbreite aussendet
	private static readonly viewTypeByWidth$ = new BehaviorSubject<ViewType>(1);

	// Observable, dass die beiden BehaviorSubjects viewTypeByUser$ und viewTypeByWidth$ zusammenführt und für die Subscription
	// in anderen Komponenten bereitstellt. Wenn der viewTypeByUser$ auf auto steht, dann sendet currentViewType$ den viewTypeByWidth$,
	// sonst den jeweiligen Wert von viewTypeByUser$
	get currentViewType$(): Observable<ViewType> {
		return ViewTypeService.viewTypeByWidth$
	}

	get currentViewTypeValue() {
		return this.currentViewType;
	}

	// Zugriffstelle um in der Komponente view-type-switch den in der UI gesetzten Wert für ViewType in den Service zu übertragen
	set viewTypeByUser(viewType) {
		this.currentViewType = viewType;
	}

	constructor(
		private cssVariableService: CssVariableService
	) {
	}

	// Aufbau eines Nachrichten-Stromes, der sowohl beim initialen Laden als auch beim Verändern der Fenstergröße überprüft,
	// ob sich gemäß der neuen Bildschirmbreite der ViewType geändert hat. Wenn das der Fall ist, broadcastet der Stream
	// den neuen ViewType. Die Logik ist mit Library rxjs implementiert, dass in Angular Standard ist (https://rxjs.dev/guide/overview).
	public initializeViewTypeByWidthSubscription() {
		const resize$: Observable<Event> = fromEvent(window, 'resize') as Observable<Event>

		// let breakpointSm = this.cssVariableService.getByNameAsNumber("breakpoint-sm")
		let breakpointLg = this.cssVariableService.getByNameAsNumber("breakpoint-lg")
		if (breakpointLg === 0) {
			breakpointLg = 992
		}
		this.isEmbeded = isInIframe();
		const initViewType = this.calcViewType(breakpointLg)
		this.setViewType(initViewType);
		merge(resize$)
			.pipe(
				// Die Events aus beiden observable streams werden weggeworfen, weil sie keine benötigten Informationen enthalten.
				// Nur das Timing ist relevant

				map((value) => this.calcViewType(breakpointLg)))
			.subscribe((viewType) => {
				this.currentViewType = viewType;
				ViewTypeService.viewTypeByWidth$.next(viewType)
			})
	}

	public getPositionType(): ALERT_POSITION_TYPES {
		return this.isEmbeded ? ALERT_POSITION_TYPES.GLOBAL : this.currentViewType === ViewType.Desktop ? ALERT_POSITION_TYPES.ACTION_BUTTON_TOP : ALERT_POSITION_TYPES.GLOBAL;
	}

	private calcViewType(breakpointLg: number) {
		if (this.isEmbeded) {
			return ViewType.Desktop;
		}
		if (isFullscreen()) {
			return this.currentViewType;
		}
		// Für das resize$-Observable könnte innerWidth zwar auch aus dem event extrahioert werden (event.target.innerWidth).
		// Da das aber für das viewInitialized$ nicht funktioniert, wird um der Einheitlichkeit willen die Breite einfach erneut
		// aus dem globalen window-Objekt extrahiert.
		const width = window.innerWidth
		if (width > breakpointLg) {

			return ViewType.Desktop
		}
		else {
			return ViewType.Mobile
		}
	}

	private setViewType(viewType: ViewType) {
		this.currentViewType = viewType;
		ViewTypeService.viewTypeByWidth$.next(viewType)
	}
}
